<template>
	<div>
		<a-modal title="完成服务" :width="540" :visible="visible" @cancel="handleCancel">
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确认完成
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="服务时间" required>
              <a-date-picker valueFormat="YYYY-MM-DD" v-model="form.service_day" />
              <div class="mt10">
                <select-time-range v-model="form.service_time"></select-time-range>
              </div>
            </a-form-model-item>

						<a-form-model-item label="实付价格" required v-if="order.is_paid==0">
							<a-input-number v-model="form.need_pay" placeholder="请输入" :precision="0" :min="2"
								style="width: 200px;"  :formatter="value => `${value}元`" />
						</a-form-model-item>

						<a-form-model-item required label="是否有增值价格">
							<a-switch v-model="form.is_added==1" @change="changeAdded" />
						</a-form-model-item>

						<a-form-model-item label="增值价格" required v-if="form.is_added==1">
							<a-input-number v-model="form.added_price" placeholder="请输入" :precision="0" :min="2"
								style="width: 200px;"  :formatter="value => `${value}元`" />
						</a-form-model-item>
						
						<a-form-model-item label="增值说明" required v-if="form.is_added==1">
							<a-input v-model="form.added_price_info" placeholder="请输入增值说明" style="width: 300px;" ></a-input>
						</a-form-model-item>
					</a-form-model>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
  import selectTimeRange from './addAppointment/selectTimeRange.vue';
	export default {
    components: {
      selectTimeRange,
    },
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			order:{
				type:Object,
				default:function(){
					return new Object
				},
			},
		},

		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 18
				},
				form: {
					need_pay: '',
					is_added: 0,
					added_price: '',
          service_day:'',
          service_time: [],
				}
			}
		},
		methods: {

			changeAdded() {
				this.form.is_added = this.form.is_added == 1 ? 0 : 1;
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

      changeAppointTime(value) {
        this.form.service_time = value;
      },

			/**
			 * 提交数据
			 */
			onSubmit() {
				if (this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('admin/completeAppointment', {
					appointment_id: this.order.appointment_id,
					need_pay: this.form.need_pay,
					is_added: this.form.is_added,
					added_price: this.form.added_price,
					added_price_info: this.form.added_price_info,
          service_day: this.form.service_day,
          service_time: this.form.service_time,
				}).then(res => {
					this.$message.success('操作成功');
					this.$emit("ok");
					this.confirmLoading = false;
				}).catch(res => {
					console.log(res);
					this.confirmLoading = false;
				})
			},
		}
	}
</script>

<style>

</style>
