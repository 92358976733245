<template>
	<div>
		<a-modal title="取消预约" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer">
				<a-button  key="back"  @click="handleCancel" >
					不取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定取消
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<div class="flex alcenter">
						<div class="ft14 ftw500 cl-info">取消理由</div>
						<div class="ft12 ftw400 cl-notice">100字以内</div>
					</div>
					<div class="mt12">
						<a-textarea placeholder="请输入理由内容" :rows="4" v-model="form.reason" :maxLength="100"/>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			appointment_id:{
				type:Number,
				default:0
			}
		},
		
		data() {
			return {
				loading: false,
				confirmLoading:false,
				form:{
					reason:'',
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/cancelAppointment',{
					appointment_id:this.appointment_id,
					reason:this.form.reason,
				}).then(res=>{
					this.$message.success('取消成功',1,()=>{
						this.confirmLoading=false;
						this.$emit('ok');
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
	
		}
	}
</script>

<style>
	
</style>
